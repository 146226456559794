//公共的footer 组件

import React from "react";

import { UpOutlined } from "@ant-design/icons";
import Logo from "@src/images/Logo.svg";
import { Col, Collapse, Row } from "antd";

import { Link } from "gatsby";

import * as styles from "./index.module.scss";

const footerData = [
  {
    parentInfo: "产品介绍",
    sonNodes: [
      {
        targetName: "首页",
        targetUrl: "/",
      },
      {
        targetName: "Shoprise有品",
        targetUrl: "/shoprise",
      },
      {
        targetName: "套餐定价",
        targetUrl: "/pricing",
      },
      {
        targetName: "功能特性",
        targetUrl: "/product-features",
      },
      {
        targetName: "模板展示",
        targetUrl: "/theme",
      }, {
        targetName: "推广服务",
        targetUrl: "/advertising",
      },
    ],
  },
  {
    parentInfo: "服务",
    sonNodes: [
      // {
      //   targetName: "帮助中心",
      //   targetUrl: "/helpcenter",
      // },
      {
        targetName: "常见问题",
        targetUrl: "/question",
      },
      // {
      //   targetName: "投诉建议",
      //   targetUrl: "/complaints",
      // },
    ],
  },
  {
    parentInfo: "公司",
    sonNodes: [
      {
        targetName: "公司介绍",
        targetUrl: "/aboutus",
      },
      {
        targetName: "合作伙伴",
        targetUrl: "/partner",
      },
      {
        targetName: "用户协议",
        targetUrl: "/agreement",
      },
      {
        targetName: "服务条款",
        targetUrl: "/terms",
      },
      {
        targetName: "隐私政策",
        targetUrl: "/privacy",
      },
    ],
  },
];

const { Panel } = Collapse;
const IndexFooter = () => {
  return (
    <div className={styles.footer}>
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div className={styles.pcContainer}>
            <div className={styles.footerMain}>
              {footerData.map((item) => {
                return (
                  <div key={item.parentInfo}>
                    <p
                      style={{
                        color: "#111",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {item.parentInfo}
                    </p>
                    {item.sonNodes.map((item) => {
                      return (
                        <div
                          key={item.targetName}
                          style={{ lineHeight: "37px" }}
                        >
                          <Link
                            to={item.targetUrl}
                            style={{ color: "#686666" }}
                          >
                            {item.targetName}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div style={{ borderBottom: "1px solid #A6A6A6" }} />
            <div className={styles.footerIntro}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={Logo} alt="logo" style={{ marginRight: "20px" }} />
                <span style={{ marginBottom: "14px" }}>
                  <Link to="/agreement" style={{ color: "#686666" }}>
                    用户协议
                  </Link>
                  <span style={{ margin: "0 10px" }}>|</span>
                  <Link to="/terms" style={{ color: "#686666" }}>
                    服务条款
                  </Link>
                  <span style={{ margin: "0 10px" }}>|</span>
                  <Link to="/privacy" style={{ color: "#686666" }}>
                    隐私政策
                  </Link>
                </span>
              </div>
              <div style={{ textAlign: "center" }}>
                {/* <span>©2021成都泛兮科技有限公司 </span> */}
                <span>support@shoprise.com </span>
                <br />
                <span>中国 成都</span>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={0}
          style={{ paddingTop: "34px", background: "#F9FBFE" }}
        >
          <Collapse
            // defaultActiveKey={["1"]}
            expandIconPosition="right"
            bordered={false}
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
          >
            {footerData.map((item) => {
              return (
                <Panel
                  header={
                    <span
                      style={{
                        fontWeight: "400",
                        color: "#111",
                        fontSize: "16px",
                      }}
                    >
                      {item.parentInfo}
                    </span>
                  }
                  key={item.parentInfo}
                  style={{
                    border: "none",
                    backgroundColor: "#F9FBFE",
                  }}
                >
                  {item.sonNodes.map((item) => {
                    return (
                      <Link
                        to={item.targetUrl}
                        style={{ color: "#000" }}
                        key={item.targetName}
                      >
                        <div style={{ padding: "0 8px", lineHeight: "34px" }}>
                          {item.targetName}
                        </div>
                      </Link>
                    );
                  })}
                </Panel>
              );
            })}

            <div
              style={{
                margin: "0 5%",
                borderBottom: "1px solid  #686666",
                width: "90%",
                height: "1px",
                paddingTop: "15px",
                backgroundColor: "#F9FBFE",
              }}
            ></div>
          </Collapse>
          <div
            style={{
              textAlign: "center",
              color: "#2D2D2D",
              backgroundColor: "#F9FBFE",
              padding: "15px 0",
            }}
          >
            {/* <p>©2021成都泛兮科技有限公司</p> */}
            <p>support@shoprise.com</p>
            <p>中国 成都</p>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
              }}
            >
              <Link to="/agreement" style={{ color: "#000" }}>
                用户协议
              </Link>
              <Link to="/terms" style={{ color: "#000" }}>
                服务条款
              </Link>
              <Link to="/privacy" style={{ color: "#000" }}>
                隐私政策
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IndexFooter;
